<template>
  <div>
    <button
      class="ml-8 primary"
      :disabled="disabled"
      @mousedown="exportToExcel"
    >
      <span class="flex">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
          <path
            d="M0 2C0 .9.9 0 2 0h16a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm14 12h4V2H2v12h4c0 1.1.9 2 2 2h4a2 2 0 0 0 2-2zM9 8V5h2v3h3l-4 4-4-4h3z"
          />
        </svg>
        Download Report
      </span>
    </button>
    <div id="exportTable" class="hidden" v-if="isRefresh">
      <Table
        :table-headers="infoTableHeaders"
        :table-data-rows="infoTableDataRows"
        v-if="infoTableDataRows.length > 0"
        :headerColor="isHeaderColor"
        :isVerticalTable="isVerticalTable"
      />
      <br />
      <Table
        :table-headers="tableHeaders"
        :table-data-rows="exportData"
        v-if="exportData.length > 0"
        :headerColor="isHeaderColor"
      />
    </div>
  </div>
</template>

<script>
export default {
  components: {
    Table: () => import(/* webpackPrefetch: true */ "../common/Table"),
  },
  data: () => ({
    isHeaderColor: true,
    isHeaderTextColor: true,
    isVerticalTable: true,
    infoTableHeaders: [],
    isRefresh: true,
  }),
  computed: {
    disabled() {
      return this.exportData.length > 0 ? false : true;
    },
  },
  props: {
    infoTableDataRows: {
      type: Array,
    },
    exportData: {
      type: Array,
    },
    tableHeaders: {
      type: Array,
    },
    fileName: {
      type: String,
    },
  },
  methods: {
    exportToExcel: function() {
      let style =
        "<style>.green { background-color: #339967; color: #ffffff; }</style>";
      let uri = "data:application/vnd.ms-excel;base64,",
        template =
          '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]-->' +
          style +
          "</head><body>{table}</body></html>",
        base64 = function(s) {
          return window.btoa(unescape(encodeURIComponent(s)));
        },
        format = function(s, c) {
          return s.replace(/{(\w+)}/g, function(m, p) {
            return c[p];
          });
        };
      var ctx = {
        worksheet: "Worksheet",
        table: document.getElementById("exportTable").innerHTML,
      };
      let a = document.createElement("a");
      a.href = uri + base64(format(template, ctx));
      a.download = this.fileName + ".xls";
      a.click();
    },
  },
  watch: {
    exportData() {
      this.isRefresh = false;
      this.$nextTick(() => {
        this.isRefresh = true;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
button {
  padding: 4px 10px 6px 10px;
  font-size: 1rem;
  font-weight: 600;
  outline: 0;
  border-radius: 3px;
  transition: all 0.3s ease 0s;

  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: auto;
  outline: none;
  &[disabled] {
    cursor: not-allowed;
    opacity: 0.6;
  }
  &.grey {
    background-color: #ddd;
    color: #444;
    border-color: #ddd;
    &:hover {
      opacity: 0.8;
    }
  }
  &.white {
    background-color: #fff;
    color: #444;
    border-color: #9ac3ea;
    &:hover {
      opacity: 0.8;
    }
  }
  &.green {
    background-color: #00a65a;
    border-color: #00a65a;
    color: #fff;
    &:hover {
      opacity: 0.8;
    }
  }
  &.primary {
    background-color: #046da2;
    border-color: #046da2;
    color: #fff;
    &:hover {
      opacity: 0.8;
    }
  }
  svg {
    width: 13px;
    margin-right: 5px;
    padding-top: 3px;
    fill: #fff;
  }
}
.modal-footer {
  button {
    margin-left: 0px;
  }
}
*::-ms-backdrop,
button {
  height: 30px;
  align-items: flex-start;
  padding: 8px 10px 6px 10px;
}
*::-ms-backdrop,
button svg {
  padding-top: 0px;
}
</style>
